<template>
  <div class="users-trash fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="cashBoxTrashList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @cash:restore="restoreCashBox($event)"
                             @cash:deleted="$refs.deleteConfirmation.showDialog(), cashDataForDelete = $event"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getCashBoxesTrash()"/>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.cashBoxes.confirmations.delete.title')}`"
                   :body="`${$t('treasury.cashBoxes.confirmations.delete.body', {cashBox: cashDataForDelete.name})}`"
                   @accept="deleteCashBox(cashDataForDelete)"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {deleteUser, getUsersTrash, restoreUser} from '@/http/requests/users/users'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from '@/assets/js/functions'
import {deleteCashBoxes, getCashBoxesTrash, restoreCashBoxes} from '@/http/requests/cashBoxes'

export default {
  name: 'cashBoxesTrash',
  components: {CustomDialog, DraggableDynamicTable},
  metaInfo () {
    return {
      title: this.$t('treasury.cashBoxes.trash.title')
    }
  },
  data () {
    return {
      requestSent: false,
      loadingTimer: 0,
      options: {
        id: 'usersTrashList',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'storeAction',
          i18n: 'treasury.cashBoxes.table.header.restore',
          color: 'success',
          width: '70px',
          minWidth: 70,
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'cash:restore',
          action: {
            icon: 'icon-refresh-cw',
            iconPack: 'feather',
            color: 'success',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'deleteAction',
          i18n: 'treasury.cashBoxes.table.header.delete',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          actions: true,
          showAction: 'always',
          event: 'cash:deleted',
          action: {
            icon: 'icon-trash',
            iconPack: 'feather',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'balance',
          i18n: 'treasury.cashBoxes.table.header.balance',
          width: 'calc(100% / 2)',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'treasury.cashBoxes.table.header.name',
          width: 'calc(100% / 2)',
          minWidth: 170,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.cashBoxes.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      data: [],
      page: 1,
      filters: [],
      sorts: [],
      endedList: false,
      actions: {
        list: [
          {
            id: 'printTable',
            i18n: 'draggableDynamicTable.actions.print',
            icon: 'icon-printer',
            iconPack: 'feather'
          },
          {
            id: 'downloadTable',
            i18n: 'draggableDynamicTable.actions.download',
            icon: 'icon-download',
            iconPack: 'feather'
          }
        ]
      },
      cashDataForDelete: {}
    }
  },
  created () {

    if (!checkUserPermissions('cash.forceDelete')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'deleteAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    if (!checkUserPermissions('cash.restore')) {
      for (let i = 0; i < this.columnsLabel.length; i++) {
        if (this.columnsLabel[i].field === 'storeAction') {
          this.columnsLabel.splice(i, 1)
        }
      }
    }

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', '')
    }, 50)

    this.getCashBoxesTrash()
  },
  methods: {
    getCashBoxesTrash () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.cashBoxTrashList && this.data.length === 0) this.$refs.cashBoxTrashList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.cashBoxTrashList && this.data.length > 0) this.$refs.cashBoxTrashList.loadMoreStatus = 'Loading'

            getCashBoxesTrash(this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const cashBoxes = response.data.data
              cashBoxes.forEach((cash) => {
                this.data.push({
                  row: this.data.length + 1,
                  id: cash.id,
                  name: cash.name,
                  balance: {
                    type: 'price',
                    classes: cash.balance && cash.balance < 0 ? 'text-danger' : 'text-success',
                    value: cash.balance ? cash.balance : 0
                  }
                })
              })
              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.cashBoxTrashList) this.$refs.cashBoxTrashList.loadMoreStatus = ''

              this.requestSent = false
            }).catch(() => {

              if (this.$refs.cashBoxTrashList) this.$refs.cashBoxTrashList.loadMoreStatus = 'Danger'

              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilter (filters, remove) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'name':
          if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.push(`balance=${  filters[key].search  },${  filters[key].type.id}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getCashBoxesTrash()
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

        case 'rowNumber':
          sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  sorts[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getCashBoxesTrash()
    },
    restoreCashBox (cash) {
      restoreCashBoxes(cash.id).then(res => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.restore.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getCashBoxesTrash()
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.restore.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
    deleteCashBox (cash) {
      deleteCashBoxes(cash.id, true).then(res => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.cashBoxes.notifications.delete.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check-circle',
          iconPack: 'feather'
        })

        this.data = []
        this.getCashBoxesTrash()
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          switch (error.response.status) {
            case 409:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.cashBoxes.notifications.delete.isUsedError'),
                color: 'danger',
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 5000
              })
              break

            default:
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.cashBoxes.notifications.delete.error'),
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                color: 'danger'
              })
              break
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
